import request from '@/utils/request';

const Api = {
  async getAllListApi(query) {
    return await request({
      url: '/medical/reservation',
      method: 'get',
      params: query
    });
  },
  async exportExcelApi(query) {
    return await request({
      url: '/medical/reservation/exportExcel',
      method: 'get',
      params: query
    });
  },
  async addDataApi(params) {
    return await request.post('/medical/package/create', params);
  },

  async delDataApi(params) {
    return await request.post('/medical/reservation/remove', params);
  },

  async updateDataApi(params) {
    return await request.post('/medical/reservation/audit', params);
  },
  async getDetailApi(str) {
    return await request.get('/positionInfo/detail?id=' + str);
  }
};

export default Api;
