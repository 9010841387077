<template>
  <div class="container">
    <el-tabs value="1" @tab-click="tabClick">
      <el-tab-pane label="已预约" name="1"></el-tab-pane>
      <el-tab-pane label="已通知" name="2"></el-tab-pane>
      <el-tab-pane label="已核销" name="3"></el-tab-pane>
      <el-tab-pane label="取消预约" name="4"></el-tab-pane>
    </el-tabs>
    <div class="handle-box" style="float: left;">
      <el-radio-group v-model="pageInfo.type" @change="changeToday">
        <el-radio v-if="pageInfo.status == 1" label="nDaysLater"
          >审批期</el-radio
        >
        <el-radio label="all">全部</el-radio>
      </el-radio-group>
      <el-divider direction="vertical"></el-divider>
      <el-button
        v-loading="exportLoading"
        type="primary"
        icon="el-icon-upload2"
        @click="exportToExcel"
        >导出</el-button
      >
      <el-button type="danger" icon="el-icon-delete" @click="delAll"
        >批量删除</el-button
      >
      <el-button
        v-if="pageInfo.status == 1"
        type="success"
        icon="el-icon-delete"
        @click="onExamine('', true, 1)"
        >批量审核</el-button
      >
      <el-button
        v-if="pageInfo.status == 2"
        type="success"
        icon="el-icon-delete"
        @click="onExamine('', false, 1)"
        >批量核销</el-button
      >
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入预约人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="policeNoOrTelephone">
          <el-input
            v-model="query.policeNoOrTelephone"
            placeholder="请输入警号、联系方式"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="orderDate">
          <el-date-picker
            v-model="query.orderDate"
            align="right"
            type="date"
            placeholder="选择预约日期"
            value-format="yyyy-MM-dd"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <el-table
      v-loading="listLoading"
      :data="dataList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        prop="name"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column label="性别" prop="sex" align="center" width="55">
        <template slot-scope="{ row }">{{ row.sex | sexFilter }}</template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号"
        align="center"
      ></el-table-column>
      <el-table-column
        label="警号"
        prop="policeCard"
        align="center"
      ></el-table-column>
      <el-table-column
        label="部门"
        prop="departmentName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="婚否"
        prop="marital"
        align="center"
        :formatter="marital"
      ></el-table-column>
      <el-table-column label="年龄" prop="age" align="center"></el-table-column>
      <el-table-column
        prop="hospitalName"
        label="预约医院"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="预约时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="meal"
        label="预约套餐"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.status == 1"
            type="success"
            size="mini"
            icon="el-icon-edit"
            @click="onExamine(row.id, true, 0)"
            >审核通知</el-button
          >
          <el-button
            v-if="row.status == 2"
            type="success"
            size="mini"
            icon="el-icon-edit"
            @click="onExamine(row.id, false, 0)"
            >核销</el-button
          >
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="handleDel(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :total="pageInfo.total"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/health';
import Dict from '@/components/common/dict';

export default {
  name: 'Health',
  filters: {
    sexFilter(val) {
      const sexMap = {
        1: '男',
        2: '女'
      };
      return sexMap[val];
    }
  },
  data() {
    return {
      dataList: [],
      exportLoading: false,
      dialogVisible: false,
      listLoading: false,
      handleType: 'add',
      checkboxArr: [],
      singleArr: {},
      query: {
        policeNoOrTelephone: '',
        name: '',
        orderDate: '',
        status: 1
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 50,
        departmentId: '',
        name: '',
        phone: '',
        date: '',
        status: 1,
        total: 0,
        type: 'nDaysLater',
        userId: sessionStorage.getItem('userId')
      }
    };
  },
  created() {
    this.handleGetList();
  },
  methods: {
    // 类型变更
    changeToday(v) {
      this.pageInfo.type = v;
      this.handleGetList();
    },
    //审核
    onExamine(id, handletype, isbatch) {
      var idArr = [];
      if (isbatch == 1) {
        if (this.checkboxArr.length == 0) {
          this.$message({
            message: '请至少勾选一条记录',
            type: 'erroe'
          });
          return;
        }
        this.checkboxArr.forEach(item => {
          idArr.push(item);
        });
      } else {
        idArr.push(id);
      }
      console.log('当前', idArr);
      this.$confirm('您确定要审核通知该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api
          .updateDataApi({
            ids: idArr,
            audit: handletype,
            userId: this.pageInfo.userId
          })
          .then(resp => {
            if (resp.code == 200) {
              this.$message({
                message: resp.message,
                type: 'success'
              });
              this.handleGetList();
            } else {
              this.$message.error(resp.msg);
            }
          })
          .catch(() => {
            console.log('服务器响应失败');
          });
      });
    },
    search() {
      console.log('搜索', this.query.orderDate);
      this.pageInfo.policeNoOrTelephone = this.query.policeNoOrTelephone;
      this.pageInfo.name = this.query.name;
      this.pageInfo.date = this.query.orderDate;
      this.pageInfo.status = this.query.status;
      console.log(this.pageInfo);
      this.handleGetList();
    },
    //列表
    handleGetList() {
      // eslint-disable-next-line no-irregular-whitespace
      sessionStorage.getItem('userId');

      this.listLoading = true;
      api.getAllListApi(this.pageInfo).then(response => {
        this.dataList = response.data.data;
        this.pageInfo.total = response.data.total;
        this.listLoading = false;
      });
    },
    tabClick(tab) {
      let status = tab.name;
      this.pageInfo.status = status;
      this.query.status = status;
      this.handleGetList();
    },
    //新增
    handleAdd() {
      if (this.singleArr.name == '') {
        this.$message({
          type: 'error',
          message: '请填写套餐名称'
        });
        return;
      }
      if (this.singleArr.content == '') {
        this.$message({
          type: 'error',
          message: '请填写套餐内容'
        });
        return;
      }
      const query = {
        name: this.singleArr.name,
        content: this.singleArr.content,
        remark: this.singleArr.remark
      };
      api.addDataApi(query).then(res => {
        if (res.code === 200) {
          this.handleGetList();
          this.dialogVisible = false;
          this.$message({
            type: 'success',
            message: '添加成功'
          });

          this.singleArr = {};
        }
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.handleGetList();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.handleGetList();
    },
    //excel数据导出
    exportToExcel() {
      var policeNo = this.query.policeNoOrTelephone;
      var name = this.query.name;
      var date = this.query.orderDate;
      var status = this.query.status;
      var type = this.pageInfo.type;
      var userId = sessionStorage.getItem('userId');
      const devURL = 'https://police.dznc-tech.com:5000';
      const prodURL = 'https://police.dznc-tech.com:3000';
      let url;
      if (process.env.NODE_ENV === 'development') {
        url =
          devURL +
          '/medical/reservation/exportExcel?userId=' +
          userId +
          '&status=' +
          status +
          '&type=' +
          type +
          '&policeCard=' +
          policeNo +
          '&name=' +
          name +
          '&date=' +
          date;
      } else {
        url =
          prodURL +
          '/medical/reservation/exportExcel?userId=' +
          userId +
          '&status=' +
          status +
          '&type=' +
          type +
          '&policeCard=' +
          policeNo +
          '&name=' +
          name +
          '&date=' +
          date;
      }
      window.open(url);
    },
    formatJson(filterVal, jsonData) {
      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].status = Dict.getText(
          jsonData[i].status,
          Dict.appointment_status
        );
      }
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    //批量删除
    delAll() {
      if (this.checkboxArr.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = this.checkboxArr;
        api
          .delDataApi({ ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.handleGetList();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    handleDel(id) {
      var arr = [];
      arr.push(id);
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const query = { ids: arr };
        api.delDataApi(query).then(res => {
          if (res.code === 200) {
            this.handleGetList();
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      });
    },
    showEditBox(num) {
      this.handleType = 'edit';
      this.singleArr = this.dataList[num];
      this.dialogVisible = true;
    },
    //编辑
    handleEdit() {
      var arr = [];

      for (var i = 0; i < this.singleArr.departmentId.length; i++) {
        arr.push(this.singleArr.departmentId[i][1]);
      }

      const query = {
        id: this.singleArr.id,
        name: this.singleArr.name,
        departmentId: arr,
        remark: this.singleArr.remark
      };
      api.updateDataApi(query).then(res => {
        if (res.code === 200) {
          this.handleGetList();
          this.editBoxShow = false;
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          this.dialogVisible = false;
          this.singleArr = {};
        }
      });
    },
    showAddBOX() {
      this.singleArr = {};
      this.dialogVisible = true;
    },
    handleSelectionChange(val) {
      this.checkboxArr = [];
      val.forEach(item => {
        this.checkboxArr.push(item.id);
      });
    },
    // 婚否格式化
    marital(row, column, cellValue) {
      switch (cellValue) {
        case 1:
          return '已婚';
        case 2:
          return '未婚';

        default:
          return '未填写';
      }
    }
  }
};
</script>

<style scoped></style>
